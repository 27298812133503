@import url("https://fonts.googleapis.com/css2?family=Inconsolata%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&display=swap");

.plasmic_tokens {
  --token-hOZYnLS_sIJ1: SF Pro Text;
  --plasmic-token-default: var(--token-hOZYnLS_sIJ1);
  --token-HYKZN2pBWySK: #15453d;
  --plasmic-token-primary: var(--token-HYKZN2pBWySK);
  --token-FsuDNid1kYw1: 1.25rem;
  --plasmic-token-md: var(--token-FsuDNid1kYw1);
  --token-lkalLY0x9l7V: #beccca;
  --plasmic-token-white: var(--token-lkalLY0x9l7V);
  --token-zYUZcw2OgUSK: #fdfdf7;
  --plasmic-token-bg: var(--token-zYUZcw2OgUSK);
  --token-7lV4S4XMhAPn: #7b8180;
  --plasmic-token-traur: var(--token-7lV4S4XMhAPn);
  --token-pFm8Vhx0WlLK: #000000;
  --plasmic-token-black: var(--token-pFm8Vhx0WlLK);
  --token-pI0aVSC2SDab: #ff7d41;
  --plasmic-token-active: var(--token-pI0aVSC2SDab);
  --token-Y_yBHQyDnES0: 0.9rem;
  --plasmic-token-sm: var(--token-Y_yBHQyDnES0);
  --token-8o56nlH8ivnB: #ffffff;
  --plasmic-token-white-2: var(--token-8o56nlH8ivnB);
  --token-SvAGGJqZgAvk: 30px;
  --plasmic-token-xl: var(--token-SvAGGJqZgAvk);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles,
.plasmic_default_styles:where(.global_lng_ru),
.plasmic_default_styles:where(.global_lng_en),
.plasmic_default_styles:where(.global_lng_be) {
  --mixin-bWM-qxXfrk5a_white-space: pre-wrap;
  --mixin-9sDsyudxbWAJ_color: #000000;
  --mixin-9sDsyudxbWAJ_font-size: 24px;
  --mixin-9sDsyudxbWAJ_font-weight: 600;
  --mixin-9sDsyudxbWAJ_letter-spacing: -0.5px;
  --mixin-9sDsyudxbWAJ_line-height: 1.3;
  --mixin-9sDsyudxbWAJ_white-space: pre-wrap;
  --mixin-KLOsXHbPWAqG_color: #000000;
  --mixin-KLOsXHbPWAqG_font-size: 20px;
  --mixin-KLOsXHbPWAqG_font-weight: 600;
  --mixin-KLOsXHbPWAqG_letter-spacing: -0.3px;
  --mixin-KLOsXHbPWAqG_line-height: 1.5;
  --mixin-KLOsXHbPWAqG_white-space: pre-wrap;
  --mixin-m_YjnEKOnjKh_color: #000000;
  --mixin-m_YjnEKOnjKh_font-size: 16px;
  --mixin-m_YjnEKOnjKh_font-weight: 600;
  --mixin-m_YjnEKOnjKh_line-height: 1.5;
  --mixin-m_YjnEKOnjKh_white-space: pre-wrap;
  --mixin-hJNpMmKgjRSO_color: #0070f3;
  --mixin-hJNpMmKgjRSO_white-space: pre-wrap;
  --mixin-O5gp-vOouHBn_border-left-color: #dddddd;
  --mixin-O5gp-vOouHBn_border-left-style: solid;
  --mixin-O5gp-vOouHBn_border-left-width: 3px;
  --mixin-O5gp-vOouHBn_color: #888888;
  --mixin-O5gp-vOouHBn_padding-left: 10px;
  --mixin-O5gp-vOouHBn_white-space: pre-wrap;
  --mixin-6h_jh7-qc4eU_color: #000000;
  --mixin-6h_jh7-qc4eU_font-size: 64px;
  --mixin-6h_jh7-qc4eU_font-weight: 700;
  --mixin-6h_jh7-qc4eU_letter-spacing: -1px;
  --mixin-6h_jh7-qc4eU_line-height: 1;
  --mixin-6h_jh7-qc4eU_white-space: pre-wrap;
  --mixin-nR8kC74tq-j-_color: #862424;
  --mixin-nR8kC74tq-j-_font-size: 48px;
  --mixin-nR8kC74tq-j-_font-weight: 700;
  --mixin-nR8kC74tq-j-_line-height: 1.1;
  --mixin-nR8kC74tq-j-_letter-spacing: -0.5px;
  --mixin-nR8kC74tq-j-_white-space: pre-wrap;
  --mixin-pEgdEG1GqkRJ_color: #000000;
  --mixin-pEgdEG1GqkRJ_font-size: 32px;
  --mixin-pEgdEG1GqkRJ_font-weight: 600;
  --mixin-pEgdEG1GqkRJ_line-height: 1.2;
  --mixin-pEgdEG1GqkRJ_white-space: pre-wrap;
  --mixin-ZRr_5P3tuvWB_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-ZRr_5P3tuvWB_border-bottom-color: #dddddd;
  --mixin-ZRr_5P3tuvWB_border-bottom-style: solid;
  --mixin-ZRr_5P3tuvWB_border-bottom-width: 1px;
  --mixin-ZRr_5P3tuvWB_border-left-color: #dddddd;
  --mixin-ZRr_5P3tuvWB_border-left-style: solid;
  --mixin-ZRr_5P3tuvWB_border-left-width: 1px;
  --mixin-ZRr_5P3tuvWB_border-right-color: #dddddd;
  --mixin-ZRr_5P3tuvWB_border-right-style: solid;
  --mixin-ZRr_5P3tuvWB_border-right-width: 1px;
  --mixin-ZRr_5P3tuvWB_border-top-color: #dddddd;
  --mixin-ZRr_5P3tuvWB_border-top-style: solid;
  --mixin-ZRr_5P3tuvWB_border-top-width: 1px;
  --mixin-ZRr_5P3tuvWB_border-bottom-left-radius: 3px;
  --mixin-ZRr_5P3tuvWB_border-bottom-right-radius: 3px;
  --mixin-ZRr_5P3tuvWB_border-top-left-radius: 3px;
  --mixin-ZRr_5P3tuvWB_border-top-right-radius: 3px;
  --mixin-ZRr_5P3tuvWB_font-family: "Inconsolata";
  --mixin-ZRr_5P3tuvWB_padding-bottom: 1px;
  --mixin-ZRr_5P3tuvWB_padding-left: 4px;
  --mixin-ZRr_5P3tuvWB_padding-right: 4px;
  --mixin-ZRr_5P3tuvWB_padding-top: 1px;
  --mixin-ZRr_5P3tuvWB_white-space: pre-wrap;
  --mixin-l6sppc5phCO8_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-l6sppc5phCO8_border-bottom-color: #dddddd;
  --mixin-l6sppc5phCO8_border-bottom-style: solid;
  --mixin-l6sppc5phCO8_border-bottom-width: 1px;
  --mixin-l6sppc5phCO8_border-left-color: #dddddd;
  --mixin-l6sppc5phCO8_border-left-style: solid;
  --mixin-l6sppc5phCO8_border-left-width: 1px;
  --mixin-l6sppc5phCO8_border-right-color: #dddddd;
  --mixin-l6sppc5phCO8_border-right-style: solid;
  --mixin-l6sppc5phCO8_border-right-width: 1px;
  --mixin-l6sppc5phCO8_border-top-color: #dddddd;
  --mixin-l6sppc5phCO8_border-top-style: solid;
  --mixin-l6sppc5phCO8_border-top-width: 1px;
  --mixin-l6sppc5phCO8_border-bottom-left-radius: 3px;
  --mixin-l6sppc5phCO8_border-bottom-right-radius: 3px;
  --mixin-l6sppc5phCO8_border-top-left-radius: 3px;
  --mixin-l6sppc5phCO8_border-top-right-radius: 3px;
  --mixin-l6sppc5phCO8_font-family: "Inconsolata";
  --mixin-l6sppc5phCO8_padding-bottom: 3px;
  --mixin-l6sppc5phCO8_padding-left: 6px;
  --mixin-l6sppc5phCO8_padding-right: 6px;
  --mixin-l6sppc5phCO8_padding-top: 3px;
  --mixin-l6sppc5phCO8_white-space: pre-wrap;
  --mixin-YEVCnmXgd-cp_display: flex;
  --mixin-YEVCnmXgd-cp_flex-direction: column;
  --mixin-YEVCnmXgd-cp_align-items: stretch;
  --mixin-YEVCnmXgd-cp_justify-content: flex-start;
  --mixin-YEVCnmXgd-cp_list-style-position: outside;
  --mixin-YEVCnmXgd-cp_padding-left: 40px;
  --mixin-YEVCnmXgd-cp_position: relative;
  --mixin-YEVCnmXgd-cp_list-style-type: decimal;
  --mixin-YEVCnmXgd-cp_white-space: pre-wrap;
  --mixin-Bs2_X-yaYCTD_display: flex;
  --mixin-Bs2_X-yaYCTD_flex-direction: column;
  --mixin-Bs2_X-yaYCTD_align-items: stretch;
  --mixin-Bs2_X-yaYCTD_justify-content: flex-start;
  --mixin-Bs2_X-yaYCTD_list-style-position: outside;
  --mixin-Bs2_X-yaYCTD_padding-left: 40px;
  --mixin-Bs2_X-yaYCTD_position: relative;
  --mixin-Bs2_X-yaYCTD_list-style-type: disc;
  --mixin-Bs2_X-yaYCTD_white-space: pre-wrap;
  --mixin-EKU2a_62ScgS_color: #3291ff;
  --mixin-EKU2a_62ScgS_white-space: pre-wrap;
}

.plasmic_mixins,
.plasmic_mixins:where(.global_lng_ru),
.plasmic_mixins:where(.global_lng_en),
.plasmic_mixins:where(.global_lng_be) {
  --mixin-1hXsuXHErg5A_white-space: pre-wrap;
  --plasmic-mixin-font-bold_white-space: var(--mixin-1hXsuXHErg5A_white-space);
  --mixin-n8_qyQEaCW3x_white-space: pre-wrap;
  --plasmic-mixin-font_white-space: var(--mixin-n8_qyQEaCW3x_white-space);
  --mixin-FSO6oTO9ZbE7_white-space: pre-wrap;
  --plasmic-mixin-default-font_white-space: var(
    --mixin-FSO6oTO9ZbE7_white-space
  );
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  white-space: var(--mixin-bWM-qxXfrk5a_white-space);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-9sDsyudxbWAJ_color);
  font-size: var(--mixin-9sDsyudxbWAJ_font-size);
  font-weight: var(--mixin-9sDsyudxbWAJ_font-weight);
  letter-spacing: var(--mixin-9sDsyudxbWAJ_letter-spacing);
  line-height: var(--mixin-9sDsyudxbWAJ_line-height);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-KLOsXHbPWAqG_color);
  font-size: var(--mixin-KLOsXHbPWAqG_font-size);
  font-weight: var(--mixin-KLOsXHbPWAqG_font-weight);
  letter-spacing: var(--mixin-KLOsXHbPWAqG_letter-spacing);
  line-height: var(--mixin-KLOsXHbPWAqG_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-m_YjnEKOnjKh_color);
  font-size: var(--mixin-m_YjnEKOnjKh_font-size);
  font-weight: var(--mixin-m_YjnEKOnjKh_font-weight);
  line-height: var(--mixin-m_YjnEKOnjKh_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-hJNpMmKgjRSO_color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-O5gp-vOouHBn_color);
  padding-left: var(--mixin-O5gp-vOouHBn_padding-left);
  border-left: var(--mixin-O5gp-vOouHBn_border-left-width)
    var(--mixin-O5gp-vOouHBn_border-left-style)
    var(--mixin-O5gp-vOouHBn_border-left-color);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-6h_jh7-qc4eU_color);
  font-size: var(--mixin-6h_jh7-qc4eU_font-size);
  font-weight: var(--mixin-6h_jh7-qc4eU_font-weight);
  letter-spacing: var(--mixin-6h_jh7-qc4eU_letter-spacing);
  line-height: var(--mixin-6h_jh7-qc4eU_line-height);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-nR8kC74tq-j-_color);
  font-size: var(--mixin-nR8kC74tq-j-_font-size);
  font-weight: var(--mixin-nR8kC74tq-j-_font-weight);
  line-height: var(--mixin-nR8kC74tq-j-_line-height);
  letter-spacing: var(--mixin-nR8kC74tq-j-_letter-spacing);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-pEgdEG1GqkRJ_color);
  font-size: var(--mixin-pEgdEG1GqkRJ_font-size);
  font-weight: var(--mixin-pEgdEG1GqkRJ_font-weight);
  line-height: var(--mixin-pEgdEG1GqkRJ_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-ZRr_5P3tuvWB_font-family);
  border-radius: var(--mixin-ZRr_5P3tuvWB_border-top-left-radius)
    var(--mixin-ZRr_5P3tuvWB_border-top-right-radius)
    var(--mixin-ZRr_5P3tuvWB_border-bottom-right-radius)
    var(--mixin-ZRr_5P3tuvWB_border-bottom-left-radius);
  padding: var(--mixin-ZRr_5P3tuvWB_padding-top)
    var(--mixin-ZRr_5P3tuvWB_padding-right)
    var(--mixin-ZRr_5P3tuvWB_padding-bottom)
    var(--mixin-ZRr_5P3tuvWB_padding-left);
  border-top: var(--mixin-ZRr_5P3tuvWB_border-top-width)
    var(--mixin-ZRr_5P3tuvWB_border-top-style)
    var(--mixin-ZRr_5P3tuvWB_border-top-color);
  border-right: var(--mixin-ZRr_5P3tuvWB_border-right-width)
    var(--mixin-ZRr_5P3tuvWB_border-right-style)
    var(--mixin-ZRr_5P3tuvWB_border-right-color);
  border-bottom: var(--mixin-ZRr_5P3tuvWB_border-bottom-width)
    var(--mixin-ZRr_5P3tuvWB_border-bottom-style)
    var(--mixin-ZRr_5P3tuvWB_border-bottom-color);
  border-left: var(--mixin-ZRr_5P3tuvWB_border-left-width)
    var(--mixin-ZRr_5P3tuvWB_border-left-style)
    var(--mixin-ZRr_5P3tuvWB_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-l6sppc5phCO8_font-family);
  border-radius: var(--mixin-l6sppc5phCO8_border-top-left-radius)
    var(--mixin-l6sppc5phCO8_border-top-right-radius)
    var(--mixin-l6sppc5phCO8_border-bottom-right-radius)
    var(--mixin-l6sppc5phCO8_border-bottom-left-radius);
  padding: var(--mixin-l6sppc5phCO8_padding-top)
    var(--mixin-l6sppc5phCO8_padding-right)
    var(--mixin-l6sppc5phCO8_padding-bottom)
    var(--mixin-l6sppc5phCO8_padding-left);
  border-top: var(--mixin-l6sppc5phCO8_border-top-width)
    var(--mixin-l6sppc5phCO8_border-top-style)
    var(--mixin-l6sppc5phCO8_border-top-color);
  border-right: var(--mixin-l6sppc5phCO8_border-right-width)
    var(--mixin-l6sppc5phCO8_border-right-style)
    var(--mixin-l6sppc5phCO8_border-right-color);
  border-bottom: var(--mixin-l6sppc5phCO8_border-bottom-width)
    var(--mixin-l6sppc5phCO8_border-bottom-style)
    var(--mixin-l6sppc5phCO8_border-bottom-color);
  border-left: var(--mixin-l6sppc5phCO8_border-left-width)
    var(--mixin-l6sppc5phCO8_border-left-style)
    var(--mixin-l6sppc5phCO8_border-left-color);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-YEVCnmXgd-cp_display);
  flex-direction: var(--mixin-YEVCnmXgd-cp_flex-direction);
  align-items: var(--mixin-YEVCnmXgd-cp_align-items);
  justify-content: var(--mixin-YEVCnmXgd-cp_justify-content);
  list-style-position: var(--mixin-YEVCnmXgd-cp_list-style-position);
  padding-left: var(--mixin-YEVCnmXgd-cp_padding-left);
  position: var(--mixin-YEVCnmXgd-cp_position);
  list-style-type: var(--mixin-YEVCnmXgd-cp_list-style-type);
  flex-column-gap: var(--mixin-YEVCnmXgd-cp_flex-column-gap);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-Bs2_X-yaYCTD_display);
  flex-direction: var(--mixin-Bs2_X-yaYCTD_flex-direction);
  align-items: var(--mixin-Bs2_X-yaYCTD_align-items);
  justify-content: var(--mixin-Bs2_X-yaYCTD_justify-content);
  list-style-position: var(--mixin-Bs2_X-yaYCTD_list-style-position);
  padding-left: var(--mixin-Bs2_X-yaYCTD_padding-left);
  position: var(--mixin-Bs2_X-yaYCTD_position);
  list-style-type: var(--mixin-Bs2_X-yaYCTD_list-style-type);
  flex-column-gap: var(--mixin-Bs2_X-yaYCTD_flex-column-gap);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-EKU2a_62ScgS_color);
}
