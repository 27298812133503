.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.root > * {
  grid-column: 4;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-HYKZN2pBWySK);
  width: 200px;
  height: 200px;
  display: block;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: var(--token-HYKZN2pBWySK);
  font-size: var(--token-SvAGGJqZgAvk);
  font-weight: 300;
  text-align: center;
  margin-top: 27px;
  min-width: 0;
}
